@import "bootstrap/dist/css/bootstrap.min.css";
@import "assets/css/animate.css";
@import "assets/css/cssanimation.min.css";
@import "assets/css/style.css";
@import "assets/css/responsive.css";
@import "assets/css/color.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* global.css */
@font-face {
    font-family: 'Billion Dreams';
    src: url('./assets/fonts/BillionDreams.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  